import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  Table,
  Modal,
  Form,
  Image,
  Pagination,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Contract, BrowserProvider, formatUnits } from "ethers";
import NFTDataFetcherABI from "../abis/NFTDataFetcherABI.json"; // Import the ABI of the NFTDataFetcher contract
import CollectionABI from "../abis/CollectionABI.json"; // Import the ABI of the Collection contract
import NodesABI from "../abis/NodesABI.json"; // Import the ABI of the Collection contract
import SETTINGS from "../SETTINGS";
import debounce from "lodash.debounce"; // Add lodash.debounce for debouncing
import { BiInfoCircle, BiBox, BiCoin, BiCalendar, BiClipboard, BiDetail } from 'react-icons/bi';
import logoToken from "../logoToken.svg";
import { useNavigate } from 'react-router-dom';


const truncateAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const formatDateWithDots = (timestamp) => {
  const date = new Date(parseInt(timestamp) * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

function Admin({ provider, isConnected }) {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [txMessage, setTxMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [mintAddress, setMintAddress] = useState("");
  const [mintQuantity, setMintQuantity] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTokenTypes, setSelectedTokenTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nftsPerPage, setNftsPerPage] = useState(25);
  const [sortConfig, setSortConfig] = useState({
    key: "tokenId",
    direction: "ascending",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (isConnected) {
      fetchAllMintedNFTs();
    }
  }, [isConnected]);

  const fetchAllMintedNFTs = async () => {
    setLoading(true);

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftDataFetcherContract = new Contract(
        SETTINGS.dataFetcherContract, // Use the address of the NFTDataFetcher contract
        NFTDataFetcherABI,
        signer
      );

      const allNFTs = await nftDataFetcherContract.getAllMintedNFTDetails();
      console.log(allNFTs);
      let airdropPowerByAddress = {};
      for(let i=0;i<allNFTs.length;i++){
        let nft = allNFTs[i];
        let addr = nft[2];
        let tokenType = parseInt(nft[7]).toString();
        let airdropPower = SETTINGS.NODE_INFO[tokenType].airdrop_power;

         // If the address already exists in the dictionary, add the current airdrop power to it
        if (airdropPowerByAddress[addr]) {
          airdropPowerByAddress[addr] += airdropPower;
      } else {
          // If the address doesn't exist in the dictionary, initialize it with the current airdrop power
          airdropPowerByAddress[addr] = airdropPower;
     }
      }
      console.log(airdropPowerByAddress);
      setNfts(allNFTs);
    } catch (error) {
      console.error("Error fetching NFTs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleMint = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nodesContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );
      await nodesContract.mintAdmin(mintAddress, mintQuantity);
      setTxMessage("NFTs successfully minted!");
      fetchAllMintedNFTs(); // Refresh all NFTs after minting
    } catch (error) {
      console.error("Error minting NFTs:", error);
      setTxMessage("Error minting NFTs.");
    }
    setShowModal(false);
  };



  const handleSearch = (e) => {

    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(1);
  };
  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleTokenTypeFilter = (e) => {
    const { value, checked } = e.target;
    setSelectedTokenTypes((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((type) => type !== value)
    );
  };

  const filteredNfts = useMemo(() => {
    const filtered = nfts.filter((nft) => {
      const matchesSearch =
        searchTerm === "" || nft.owner.toLowerCase().includes(searchTerm);
      const matchesTokenType =
        selectedTokenTypes.length === 0 ||
        selectedTokenTypes.includes(nft.tokenType.toString());
      return matchesSearch && matchesTokenType;
    });

    // Create a new array for sorting to avoid modifying the original state
    const sorted = [...filtered].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [nfts, searchTerm, sortConfig, selectedTokenTypes]);

  // Determine the NFTs to display on the current page
  const currentNFTs = useMemo(() => {
    const startIndex = (currentPage - 1) * nftsPerPage;
    const endIndex = Math.min(startIndex + nftsPerPage, filteredNfts.length);
    return filteredNfts.slice(startIndex, endIndex);
  }, [filteredNfts, currentPage, nftsPerPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const copyToClipboard = (text) => {
    console.log(text);
    navigator.clipboard.writeText(text);
  };

  const openPolyscan = (address) => {
    const polyscanUrl = `https://optimistic.etherscan.io/address/${address}`;
    window.open(polyscanUrl, "_blank");
  };

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <br />
        <br />
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <br />
        <h6 className="loaderMsg">{txMessage}</h6>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col className="admin">
          <h2>Minted NFTs</h2>
          <div className="mb-3">Total Rows: {filteredNfts.length}</div>
         
          <div className="d-flex justify-content-between mb-3">
            <InputGroup>
           
            <FormControl
            style={{maxWidth:"300px", height:"40px", padding:"0px"}}
                type="text"
                placeholder="Search by Owner"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Button variant="secondary" onClick={clearSearch} 
              className="clear"
              style={{marginRight:"10px"}}
              >
                Clear
              </Button>
              <Form.Control
            className="selectSmall"
              as="select"
              value={nftsPerPage}
              onChange={(e) => setNftsPerPage(Number(e.target.value))}
            >
              {[25, 50, 100, 200].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </Form.Control>
            <Button variant="primary" onClick={handleShowModal} className="ml-2"
            style={{borderRadius:"8px"}}
            >
              Mint Admin
            </Button>
            </InputGroup>
           
            <Pagination>
            {[...Array(Math.ceil(filteredNfts.length / nftsPerPage)).keys()].map(
              (number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === currentPage}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
          </div>
          <div className="d-flex justify-content-between mb-3">
          <div>
             
              {Object.keys(SETTINGS.NODE_INFO).map((tokenType) => (
                <> <Image
                src={SETTINGS.NODE_INFO[tokenType.toString()].imgUrl}
                rounded
                className="me-1 mb-1"
                style={{ width: "40px", height: "40px" }}
              />
                <Form.Check
                  inline
                  key={tokenType}
                  label={tokenType}
                  value={tokenType}
                  type="checkbox"
                  checked={selectedTokenTypes.includes(tokenType)}
                  onChange={handleTokenTypeFilter}
                />
                </>
              ))}
            </div>
            </div>
          <Table striped bordered hover style={{background:"#FFFFFF"}}>
            <thead>
              <tr>
                <th onClick={() => handleSort("tokenId")}>
                  ID{" "}
                  {sortConfig.key === "tokenId" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("tokenByIndex")}>
                  Index{" "}
                  {sortConfig.key === "tokenByIndex" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("tokenType")}>
                  Type{" "}
                  {sortConfig.key === "tokenType" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("owner")}>
                  Owner{" "}
                  {sortConfig.key === "owner" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("lastClaim")}>
                  Last Claim{" "}
                  {sortConfig.key === "lastClaim" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>

                <th onClick={() => handleSort("unclaimedRewards")}>
                  Unclaimed{" "}
                  {sortConfig.key === "unclaimedRewards" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("referralCode")}>
                  Ref Code{" "}
                  {sortConfig.key === "referralCode" &&
                    (sortConfig.direction === "ascending" ? "↑" : "↓")}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentNFTs.map((nft) => (
                <tr key={nft.tokenId}>
                  <td>{nft.tokenId.toString()}</td>
                  <td>{nft.tokenByIndex.toString()}</td>
                  <td>
                    <Image
                      src={SETTINGS.NODE_INFO[nft.tokenType.toString()].imgUrl}
                      rounded
                      className="me-1 mb-1"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => openPolyscan(nft.owner)}
                      >
                        {truncateAddress(nft.owner)}
                      </span>
                      <Button
                        variant="link"
                        onClick={() => copyToClipboard(nft.owner)}
                        style={{ marginLeft: "10px" }}
                      >
                        <BiClipboard className='me-1' />
                      </Button>
                      <Button
  variant="link"
  onClick={() => navigate(`/user/${nft.owner}`)} // Navigate to AdminUser page
  style={{ marginLeft: "10px" }}
>
  <BiDetail className="me-1" />
</Button>
                    </div>
                  </td>
                  <td>
                    <small>{formatDateWithDots(parseInt(nft.lastClaim))}</small>
                  </td>
                  <td>
                    <small>
                      <img
                        src={logoToken}
                        className="tokenIconColor"
                        style={{
                          width: "16px",
                          marginRight: "6px",
                          marginLeft: "4px",
                          marginBottom: "2px",
                        }}
                        alt={SETTINGS.tokenSymbol}
                      />
                      {parseFloat(
                        formatUnits(nft.unclaimedRewards, 18)
                      ).toLocaleString("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </small>
                  </td>
                  <td>
                    <small>{nft.referralCode}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(Math.ceil(filteredNfts.length / nftsPerPage)).keys()].map(
              (number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === currentPage}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Col>
      </Row>

      {/* Modal for Minting */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mint Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="mintAddress">
              <Form.Label>Mint Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                value={mintAddress}
                onChange={(e) => setMintAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="mintQuantity">
              <Form.Label>Mint Quantity</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={mintQuantity}
                onChange={(e) => setMintQuantity(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleMint}>
            Mint
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Admin;
