import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Spinner, Image, Button } from "react-bootstrap";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import { useParams, useNavigate } from "react-router-dom";
import NFTDataFetcherABI from "../abis/NFTDataFetcherABI.json";
import StakingABI from "../abis/StakingABI.json";
import { BiClipboard } from "react-icons/bi";
import SETTINGS from "../SETTINGS";

// Utility function to truncate addresses for display
const truncateAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

function AdminUser({ provider, isConnected }) {
  const { userAddress } = useParams(); // Get the user address from the URL parameters
  const [loading, setLoading] = useState(true);
  const [nfts, setNfts] = useState([]);
  const [stakes, setStakes] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isConnected) {
      fetchUserData();
    }
  }, [isConnected]);

  // Fetch user data and NFTs
  const fetchUserData = async () => {
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftDataFetcherContract = new Contract(
        SETTINGS.dataFetcherContract,
        NFTDataFetcherABI,
        signer
      );

      // Get all NFTs owned by the user
      const allNFTs = await nftDataFetcherContract.getAllMintedNFTDetails();
      const userNFTs = allNFTs.filter((nft) => nft.owner.toLowerCase() === userAddress.toLowerCase());

      setNfts(userNFTs);
    
      // Calculate airdrop power
      const airdropPower = userNFTs.reduce((total, nft) => {
        const tokenInfo = SETTINGS.NODE_INFO[nft.tokenType.toString()];
        return total + (tokenInfo ? tokenInfo.airdrop_power : 0);
      }, 0);

      // Fetch balances
      const qvrsBalance = await getERC20Balance(userAddress, SETTINGS.tokenAddress, signer);
      const usdcBalance = await getERC20Balance(userAddress, SETTINGS.usdtAddress, signer, 6);
      const maticBalance = await getMaticBalance(userAddress, ethersProvider);

          // Fetch stakes
          const stakingContract = new Contract(
            SETTINGS.stakingContract, // Make sure to add this address to your SETTINGS
            StakingABI,
            signer
          );
    
          const userStakes = await stakingContract.getUserStakes(userAddress);
          
          setStakes(userStakes);

      setUserData({
        address: userAddress,
        qvrsBalance,
        usdcBalance,
        maticBalance,
        airdropPower,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  

  const getERC20Balance = async (address, contractAddress, signer, decimals = 18) => {
    const contract = new Contract(contractAddress, [
      "function balanceOf(address owner) view returns (uint256)",
      "function decimals() view returns (uint8)",
    ], signer);
    const balance = await contract.balanceOf(address);
    return parseFloat(formatUnits(balance, decimals));
  };

  const getMaticBalance = async (address, provider) => {
    const balance = await provider.getBalance(address);
    return parseFloat(formatUnits(balance, 18));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Address copied to clipboard");
  };

  const openPolyscan = (address) => {
    const polyscanUrl = `https://optimistic.etherscan.io/address/${address}`;
    window.open(polyscanUrl, "_blank");
  };

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <br />
        <br />
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <br />
      </div>
    );
  }

  if (!userData) {
    return <div>User data not found.</div>;
  }

  return (
    <div>
      <Button variant="secondary" onClick={() => navigate(-1)} className="mb-3">
        Back
      </Button>
      <Card>
        <Card.Header>
          <h4>User Details</h4>
        </Card.Header>
        <Card.Body>
          <Row className="mb-3">
            <Col md={12}>
              <strong>Address:</strong>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => openPolyscan(userData.address)}
                >
                  {truncateAddress(userData.address)}
                </span>
                <Button
                  variant="link"
                  onClick={() => copyToClipboard(userData.address)}
                  style={{ marginLeft: "10px" }}
                >
                  <BiClipboard className="me-1" />
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <strong>QVRS Balance:</strong> {userData.qvrsBalance.toFixed(2)}
            </Col>
            <Col md={4}>
              <strong>USDC Balance:</strong> {userData.usdcBalance.toFixed(2)}
            </Col>
            <Col md={4}>
              <strong>MATIC Balance:</strong> {userData.maticBalance.toFixed(2)}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <strong>Total Airdrop Power:</strong> {userData.airdropPower}
            </Col>
          </Row>
          <h5>Minted NFTs</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Title</th>
                <th>Airdrop Power</th>
              </tr>
            </thead>
            <tbody>
              {nfts.map((nft) => {
                const tokenInfo = SETTINGS.NODE_INFO[nft.tokenType.toString()];
                return (
                  <tr key={nft.tokenId}>
                    <td>{nft.tokenId.toString()}</td>
                    <td>
                    <Image
                      src={SETTINGS.NODE_INFO[nft.tokenType.toString()].imgUrl}
                      rounded
                      className="me-1 mb-1"
                      style={{ width: "40px", height: "40px" }}
                    />
                    </td>
                    <td>{tokenInfo ? tokenInfo.title : "Unknown"}</td>
                    <td>{tokenInfo ? tokenInfo.airdrop_power : 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <h5>User Stakes</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Timestamp</th>
                <th>Duration</th>
                <th>Claimed</th>
              </tr>
            </thead>
            <tbody>
              {stakes.map((stake, index) => (
                <tr key={index}>
                  <td>{formatUnits(stake.amount, 18)} QVRS</td>
                  <td>{new Date(parseFloat(stake.timestamp) * 1000).toLocaleString()}</td>
                  <td>{stake.duration} seconds</td>
                  <td>{stake.claimed ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminUser;
