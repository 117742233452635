import React, { useEffect, useState } from 'react';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.png';
import { useContract } from '../hooks/useContract';
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from '../abis/QtechStakingABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Button, Alert, Card, Badge } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import CardNode from '../components/CardNode';
import LevelsLegend from '../components/LevelsLegend';
import RefferalComponent from '../components/RefferalComponent';
import LevelComponent from '../components/LevelComponent';
import CountdownTimer from '../components/CountdownTimer';
import { useNavigate } from 'react-router-dom';
import StripeLoaderImg from '../stripe_loader.svg';
import metaMaskIcon from '../metaMask.svg';

function Dashboard({ provider, address, isConnected }) {
  const { ownedNFTs, totalRewards, ownedNFTsLoaded, airdropPower, totalDailyRewards, distinctElements, NFTTypeCount, ownedQworlds } = useContract(provider);
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };
  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      //const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      //const USDTBalance = await USDTContract.balanceOf(address);
      //setUsdtBalance(parseFloat(formatUnits(USDTBalance, 6)).toFixed(2));

      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(address);
      setTokenBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      await getUserLevel();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openBuyToken = () => {
    navigate('/buy-token');
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openBuyNode = () => {
    navigate('/qvrs-worlds');
  }
  const openCraft = () => {
    navigate('/craft');
  }

  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = logoToken;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log("Error importing token to Metamask:", error);
    }
  };

  const closeStake = async (stakeId) => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      setTxMessage("Closing Stake...");
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const tx = await contract.completeStake(parseInt(stakeId));
      await tx.wait();
      setTxMessage("Stake closed!");
    }
    catch (error) {
      console.error("Error closing stake:", error);
      setTxMessage("Failed to close stake. Please try again.");
    }
    finally {
      await getBalance();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };
 

  useEffect(() => {
    if (isConnected) {
      getBalance();
    }
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <img src={StripeLoaderImg} style={{ marginTop: "300px" }} />
        <br />
        <h6 className='loaderMsg'>{txMessage}</h6>
      </div>
    );
  }

 

  return (
    <>
     


     
      <LevelsLegend currentLevel={userLevel} />
      <Row className="mb-3 mt-2 pt-md-3 pt-0 mt-md-4">
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
          <div className="nft-card pt-0 ps-0">
            <LevelComponent provider={provider} isConnected={isConnected} nodeAmount={ownedNFTs.length} />
            <Card.Body>
              <Card.Text>
                <h6 className='fw-normal mt-4 pt-3'>
                  <small>Unclaimed:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalRewards)} {SETTINGS.tokenSymbol}</b>
                </h6>
                <h6 className='fw-normal mt-2'>
                  <small>Daily {SETTINGS.tokenSymbol} Generation:</small>
                  <Badge pill bg="primary" className='mb-2 pill-blue px-3 ms-2'>
                    {totalDailyRewards} <small>{SETTINGS.tokenSymbol}</small>
                  </Badge><br />
                  <small>{SETTINGS.tokenSymbol} generation ends in: </small>
                  <CountdownTimer targetTimestamp={SETTINGS.END_TIME} />
                </h6>
              </Card.Text>
            </Card.Body>
          </div>
        </Col>

        {/* LAND */}
        <Col sm={12} md={6} lg={8} xl={8} className="mb-3">
          {!ownedNFTsLoaded ? (
            <Row>
              <Col xs={12} style={{ padding: "0 9px" }}>
                <Card className='card-fix text-center'>
                  <Card.Body>
                    <br />
                    <br />
                    <br />
                    <img src={StripeLoaderImg} />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </Card.Body>
                </Card>
              </Col>

            </Row>

          ) : (

            <Row>

            
              <Col xs={6} sm={6} md={6} lg={6} xl={4} className="mb-md-3 mb-1" style={{ padding: "0 9px" }}>
                <Card className='card-fix text-center pt-3 chat-message card-balance'>
                  <Card.Title>
                  {SETTINGS.tokenSymbol} balance
                  </Card.Title>
                  <Card.Img variant="top" src={logoToken} style={{ opacity: "1", maxHeight: "100px", marginTop: "0px", marginBottom: "0px" }} />
                 
                  <Card.Body className='pt-md-3 pt-2'>
                    <p className='mb-0 small'><b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</b></p>
                    <Button variant="primary" className='buton buton-secondary mb-0' onClick={importTokenToMetamask}>
                  IMPORT TO
                  <img src={metaMaskIcon} style={{ width: "20px", marginTop: "-3px", marginLeft: "5px" }} alt="metaMaskIcon" />
                </Button>
                  </Card.Body>
                </Card>
              </Col>

              {/* Airdrop power */}
<Col xs={6} sm={6} md={6} lg={6} xl={4} className="mb-md-3 mb-1" style={{ padding: "0 9px" }}>
  <Card className='card-fix text-center pt-3 chat-message card-balance'>
    <Card.Title>
      AIRDROP POWER
    </Card.Title>
    
    <Card.Body className='pt-0'>
      
      
      <hr />
            {/* Conversion Rate */}
            <p className='mb-0 small'>
        Each <img src={parachuteImg} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"AP"} />
         gets you
        <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
        <b>{SETTINGS.AP_PRICE} {SETTINGS.tokenSymbol}</b> at next airdrop happening in: <CountdownTimer targetTimestamp={SETTINGS.NEXT_AIRDROP} />
      </p>
      <hr />
      {/* Current AP Balance */}
     
      <p className='mb-0 small'>
        
       
          Balance: 
          <b>
          <img src={parachuteImg} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"AP"} />
          {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(airdropPower)} AP
        </b>
      </p>
      <p className='mb-0 small'>
        
       
          Drop: 
          <b>
          <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"AP"} />
          {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseInt(airdropPower)*SETTINGS.AP_PRICE)} {SETTINGS.tokenSymbol}
        </b>
      </p>

    </Card.Body>
  </Card>
</Col>

              {/* Referral */}
              <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-3" style={{ padding: "0 9px" }}>
                <Card className='card-fix text-center pt-3 chat-message card-balance-r'>
                  <Card.Title>
                    Referral
                  </Card.Title>
                  <Card.Body className='pt-md-4 pt-2'>
                    <RefferalComponent provider={provider} isConnected={isConnected} nodeAmount={ownedNFTs.length} />
                  </Card.Body>
                </Card>
              </Col>

              {/* LANDS */}
              {ownedQworlds.map((nft, index) => (
                <Col xs={6} sm={6} md={6} lg={6} xl={4} className="mb-3" key={index} style={{ padding: "0 9px" }}>
                  <CardNode
                    nft={nft}
                    provider={provider}
                    address={address}
                    getBalanceFunction={getBalance}
                    amount={NFTTypeCount[nft.tokenType]}
                  />
                </Col>
              ))}
              {distinctElements.map((nft, index) => (
                <Col xs={6} sm={6} md={6} lg={4} xl={4} className="mb-3" key={index} style={{ padding: "0 9px" }}>
                  <CardNode
                    nft={nft}
                    provider={provider}
                    address={address}
                    getBalanceFunction={getBalance}
                    amount={NFTTypeCount[nft.tokenType]}
                  />
                </Col>
              ))}
            </Row>
          )}

        </Col>
      </Row>
      
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-5 mt-3 dmy" style={{ padding: "4px" }}>
          <div className="new_bg_1"></div>
        </Col>
      </Row>

      <Row className="mb-5 mt-3">
        <Col xs="12" sm="12" md="12" lg="4" xl="4" className="mb-md-3 mb-1" style={{ padding: "4px" }}>
          <div className=" new_block_1">
            <h2>FMT Tokens</h2>
            <span>Are the platform’s native currency. They are used for purchasing LANDS, crafting elements, staking, and as a payment method within the FingerMonkeys ecosystem.</span>
            <a href="/store"><Button variant="primary" className='buton buton-secondary mb-0'>BUY FMT TOKEN HERE</Button></a>
          </div>
        </Col>
        
        <Col xs="12" sm="12" md="12" lg="4" xl="4" className="mb-md-3 mb-1" style={{ padding: "4px" }}>
          <div className=" new_block_2">
            <h2>LANDS</h2>
            <span>Are digital assets (NFTs) that can be purchased and crafted through the FMT token dapp. These assets are essential for advancing to higher levels and unlocking greater benefits.</span>
            <a href="/store"><Button variant="primary" className='buton buton-secondary mb-0'>BECOME LAND OWNER</Button></a>
          </div>
        </Col>
        
        <Col xs="12" sm="12" md="12" lg="4" xl="4" className="mb-md-3 mb-1" style={{ padding: "4px" }}>
          <div className=" new_block_3">
            <h2>Check Out FAQs</h2>
            <span>Learn more about how our FMT token works, how your LANDS are working, where are your tokens, how to claim, where we will be listed etc...</span>
            <a href="/FAQs"><Button variant="primary" className='buton buton-secondary mb-0'>OPEN FAQ PAGE</Button></a>
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-md-3 mb-1 videoblock" style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <h2>CHECK OUT QUICK INTRODUCTION VIDEO</h2>
          <div className=" new_block_6">
            <video width="100%" height="100%" poster="video.png" controls>
              <source src="video.mp4" type="video/mp4" />
            </video>
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-md-3 mb-1" style={{ padding: "4px" }}>
          <div className=" new_block_4">
            <h2>Fingermonkeys.com</h2>
            <span>Visit our main page, for info about our project, where are we at stages, what is FingerMonkeys all about and more. We’re updating our pages all the time, so bookmark this page and come back often for more info.</span>
            <a href="https://www.fingermonkeys.com/"><Button variant="primary" className='buton buton-secondary mb-0'>GO TO WEBSITE</Button></a>
          </div>
        </Col>
        
        <Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-md-3 mb-1" style={{ padding: "4px" }}>
          <div className=" new_block_5">
            <h2>LANDS</h2>
            <span>Check our Fmgames.io testnet, where we are showcasing, how our platform will work and how we see WEB2 & WEB3 worlds merging together. With our unique approach and Earn2Play ecosystem we are paving the future of mini games gaming experiences.</span>
            <a href="https://fmgames.io/"><Button variant="primary" className='buton buton-secondary mb-0'>GO TO FMGAMES.IO</Button></a>
          </div>
        </Col>
      </Row>

    </>
  );
}

export default Dashboard;
