import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import copyImg from '../copy.png';
import StripeLoaderImg from '../stripe_loader.svg';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers'
import Transactions from '../components/Transactions';
import { useNavigate } from 'react-router-dom';

const RefferalComponent = ({ provider, isConnected, nodeAmount }) => {
    const [loading, setLoading] = useState(true);
    const [setTxMessage] = useState('');
    const [refferalCode, setRefferalCode] = useState('');
    const [userLevel, setUserLevel] = useState(1);
    const [userPercent, setUserPercent] = useState(0);
    const [userCode, setUserCode] = useState('');
    const [levelModelUrl, setLevelModelUrl] = useState("https://node.qvrse.io/");
    const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
    const navigate = useNavigate();
    const openCraft = () => {
        navigate('/craft');
    }
    const openBuyNode = () => {
        navigate('/store');
    }
    const _setReffCode = async (code) => {
        setRefferalCode(code);
    };
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
        if (parseInt(level) > 1) {
            setUserLevel(level.toString());
            const code = await collectionContract.refferalCode(signer.address);
            setUserCode(code);
            if (code != "") {
                const percent = await collectionContract.getRefferalPercent(signer.address);
                setUserPercent(percent.toString());
            }
        }
        if (parseInt(level) == 2) setLevelModelUrl("https://node1.qvrse.io/");
        if (parseInt(level) == 3) setLevelModelUrl("https://node2.qvrse.io/");
        if (parseInt(level) == 4) setLevelModelUrl("https://node3.qvrse.io/");
        if (parseInt(level) == 5) setLevelModelUrl("https://node4.qvrse.io/");
        if (parseInt(level) == 1337) setLevelModelUrl("https://node1337.qvrse.io/");
        setLoading(false);
    };
    const createCode = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            const collectionContract = new Contract(
                SETTINGS.collection,
                CollectionABI,
                signer
            );
            // Connect to the NFT contract and buy the NFT
            if (refferalCode.length <= 5) {
                alert("Should be longer than 5 characters.");
                setLoading(false);
                return;
            }
            const codeCheck = await collectionContract.codeUser(refferalCode.toUpperCase());
            if (codeCheck != "0x0000000000000000000000000000000000000000") {
                alert("Code is taken");
                setLoading(false);
                return;
            }
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const refTx = await collectionContract.createRefferalCode(refferalCode.toUpperCase());
            await refTx.wait();
        }
        catch (error) {
            console.error("Error creating code:", error);
        }
        finally {

            getUserLevel();
            // Optionally reset the transaction message after a delay
            setTimeout(() => setLoading(false), 2000);
        }
    };
    useEffect(() => {
        if (isConnected) {
            getUserLevel();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);


    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} style={{ width: "40%" }} />
                    </center>
                </div>
            </div>
        );
    }


    return (
        <div className="text-center">
            {userLevel <= 1 ? (<div className="pokemon-card" style={{ background: "#191915", border: "0px", boxShadow: "0 0 0px rgba(0, 0, 0, 0.02)" }}>
                <div className="text-center" style={{ margin: "35px 0px" }}>
                    <center>
                        Unlocks at level 2.
                    </center>
                </div>
            </div>
            ) : (
                <>
                    {userCode == "" ? (
                        <div className=''>
                            <div className='input-group' style={{ marginBottom: "14px" }}>
                                <label className='mb-1'>Create referral code</label>
                                <input
                                    type="text"
                                    value={refferalCode}
                                    onChange={(e) => _setReffCode(e.target.value)}
                                    placeholder="Referral Code"
                                    className='custom-select'
                                />
                            </div>
                            <Button className='buton buton buton-secondary d-lg-block' onClick={createCode}>CREATE</Button>
                        </div>
                    ) : (
                        <>
                            <h6 className='mb-1'>Percent: {userPercent}%</h6>
                            <small>Invite others and get a reward!</small>
                            <Button variant="primary btn-copy mt-3" onClick={() => { navigator.clipboard.writeText(userCode); }} className='buton' style={{color: "#FFB800"}}>
                                <img src={copyImg} style={{width: "13px", marginRight: "5px"}} />
                                {userCode}
                            </Button>
                            <Transactions provider={provider} />
                        </>)}
                </>)}
        </div>
    );
};

export default RefferalComponent;
