import React, { useState, useEffect } from 'react';
import { Col, Card, Row, Image, Badge, Table } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';
import NodeSaleAnnouncement from '../components/NodeSaleAnnouncement';
import { FaCoins, FaLayerGroup } from 'react-icons/fa';
import logoToken from '../logoToken.png';

const WorldInfo = ({ provider, isConnected }) => {
    const [holderContracts, setHolderContracts] = useState([]);
    const [totalLocked, setTotalLocked] = useState(0);
    const [circulating, setCirculating] = useState(0);
    const [nodeInfoData, setNodeInfoData] = useState([]);
    const loadNodeArr = () => {
        var dict = SETTINGS.NODE_INFO
        var arr = [];
        for (var key in dict) {
            arr.push(dict[key]);
        }
        setNodeInfoData(arr);
    };
    const nodeData = [
        { idRange: '0 - 1499', price: 'FMT 30 USDC' },
        { idRange: '1500 - 2999', price: 'FMT 60 USDC' },
        { idRange: '3000 - 4499', price: 'FMT 90 USDC' },
        { idRange: '4500 - 5999', price: 'FMT 120 USDC' },
        { idRange: '6000 - 7499', price: 'FMT 150 USDC' },
        { idRange: '7500 - 8999', price: 'FMT 180 USDC' },
        { idRange: '9000 - 10499', price: 'FMT 210 USDC' },
        { idRange: '10500 - 11999', price: 'FMT 240 USDC' },
        { idRange: '12000 - 13499', price: 'FMT 270 USDC' },
        { idRange: '13500 - 15000', price: 'FMT 300 USDC' }
    ];
    useEffect(() => {
        loadNodeArr();
        if (!provider) return;

    }, [provider]);

    return (
        <div className="info-container">
            <Row className="g-4">
                <Col xs={12} className='mt-md-5 mt-4'>
                    <h2 className='text-start'>{SETTINGS.tokenSymbol} Lands</h2>
                </Col>
                <Col md={6} xs={12} className='mt-md-3 mt-0'>
                <Card className="mb-3 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
                        <Card.Body style={{color: "rgba(165, 146, 142, 1)"}}>
                    <NodeSaleAnnouncement />
                    </Card.Body>
                    </Card>

                    <Card className="mb-4 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
                        <Card.Body>
                            <Card.Title style={{color: "#fff"}}><FaCoins className="me-2" />{SETTINGS.tokenSymbol} Lands Pricing</Card.Title>
                            <p style={{color: "rgba(165, 146, 142, 1)"}}>
                                The price of LANDs increases as more LANDs are sold. This is the current pricing structure based on the LAND ID range.
                            </p>
                            <div className="table-responsive mt-4">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                            <th style={{color: "#fff"}}>ID Range</th>
                                            <th style={{color: "#fff"}}>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {nodeData.map((node, index) => (
                                            <tr key={index} style={{border: "1px solid rgba(12, 13, 8, 1)"}}>
                                                <td style={{color: "rgba(165, 146, 142, 1)"}}>{node.idRange}</td>
                                                <td style={{color: "#fff"}}><img src={SETTINGS.usdtIcon} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} /> {node.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} className='mt-md-3 mt-0'>
      <Card className="mb-3 custom-card" style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
        <Card.Body>
          <Card.Title className='mb-3' style={{color: "#fff"}}><FaLayerGroup className="me-2" />LANDs and Elements</Card.Title>
          {nodeInfoData.map((node, index) => (
            <div className='pokemon-card mb-3 chat-message' key={index} style={{ padding: "10px 20px", backgroundColor: "rgba(0, 0, 0, 1)" }}>
              <Row className="align-items-center">
                {/* Img */}
                <Col sm={3} xs={8} className='offset-sm-0 offset-2'>
                <h5 className='text-uppercase mb-2 mt-0'>{node.title}</h5>
                  <Image src={node.imgUrl} rounded className="w-100" />
                  
                </Col>
                {/* Price / Items needed */}
                
                  <Col sm={4}>
                  {node.price > 0 ? (
                    <div>
                      <small><b>Buy with:</b></small>
                      <p>
                      <img src={node.buyCurrency == "USDC" ? SETTINGS.usdtIcon:logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
                        {node.price} {node.buyCurrency}</p>
                    </div>
                  ) : (
                    <div>
                      <small><b>Craft with:</b></small><br />
                      {node.craftElements.map((imgMod, index1) => (
                        <>
                        <Image key={index1} src={imgMod} rounded className="me-2 mb-2" style={{ width: '40px', height: '40px' }} />
                        {index1 == 1 && (<br />)}
                        </>
                      ))}
                    </div>
                  )}
                  {/* Rewards */}
                  <Row>
                    {node.dailyRewards > 0 && (
                      <Col xs={12} lg={6} className="mt-2 d-none">
                        <Badge pill bg="primary" className=' mb-2 pill-blue'>
                          <small>Daily: </small>{node.dailyRewards.toFixed(2)} {SETTINGS.tokenSymbol}
                        </Badge>
                      </Col>
                    )}
                    <Col xs={12} lg={12} className="mt-2 d-none">
                      <Badge pill bg="info" className='d-block mb-2'>
                        <small>Referral: </small>{node.refferalPercent} %
                      </Badge>
                    </Col>
                    {node.airdrop_power > 0 && (
                      <Col xs={12} lg={6} className="mt-2 d-none">
                        <Badge pill bg="primary" className=' mb-2 pill-pink'>
                          <small>Airdrop power: </small>{node.airdrop_power}
                        </Badge>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col sm={4}>
                  
                  <Badge pill bg="info" className='d-block mb-2'>
                        <small>Referral: </small>{node.refferalPercent} %
                      </Badge>
                      <Badge pill bg="primary" className='d-block  mb-2 pill-blue'>
                          <small>Daily: </small>{node.dailyRewards.toFixed(2)} {SETTINGS.tokenSymbol}
                        </Badge>
                        <Badge pill bg="primary" className=' mb-2 pill-pink'>
                          <small>Airdrop power: </small>{node.airdrop_power}
                        </Badge>
                  </Col>
              </Row>
            </div>
          ))}
        </Card.Body>
      </Card>
    </Col>

               

            </Row>
        </div>
    );
};

export default WorldInfo;
