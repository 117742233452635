import React, { useState, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Spinner,
  Table,
  Button,
  Pagination,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Contract, BrowserProvider, formatUnits } from "ethers";
import SETTINGS from "../SETTINGS";
import { BiClipboard, BiDetail, BiRefresh } from "react-icons/bi";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import StakingABI from '../abis/StakingABI.json';
// Define the ABI for ERC20 tokens
const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
];

const truncateAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

function AdminUsers({ provider, isConnected }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [sortConfig, setSortConfig] = useState({
    key: "address",
    direction: "ascending",
  });
  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    if (isConnected) {
      fetchUserData();
    }
  }, [isConnected]);

  // Fetch saved user data from the server
  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://files.qvrse.io/admin-token/user_data.php"
      ); // Update path as needed
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Save user data to the server
  const saveUserData = async (userData) => {
    try {
      const response = await fetch(
        "https://files.qvrse.io/admin-token/user_data.php",
        {
          // Update path as needed
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        }
      );
      const result = await response.json();
      if (result.status !== "success") {
        console.error("Failed to save user data:", result.message);
      }
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  // Fetch holders
  const fetchHolders = async () => {
    if (!isConnected || !provider) return;
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const contract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const holders = new Set();

      const latestBlock = await ethersProvider.getBlockNumber();
      const events = await contract.queryFilter(
        "Transfer",
        33720351,
        latestBlock
      );

      events.forEach((event) => {
        const value = parseInt(event.args.value);
        if (value > 0) {
          holders.add(event.args.to);
        }
      });

      // Convert Set to Array
      const holderAddresses = Array.from(holders);
      // Initialize notes for each holder
      const initialUserData = holderAddresses.map((address) => ({
        address,
        note: "",
      }));
      saveUserData(initialUserData);
      alert("Holders loaded and saved successfully!");
    } catch (error) {
      console.error("Error fetching holders:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch balances
  const fetchBalances = async () => {
    if (!isConnected || !provider) return;
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const response = await fetch(
        "https://files.qvrse.io/admin-token/user_data.php"
      ); // Update path as needed
      const userAddresses = await response.json();

      const usersData = [];
      for (let user of userAddresses) {
        const qvrsBalance = await getERC20Balance(
          user.address,
          SETTINGS.tokenAddress,
          signer
        );
        const usdcBalance = await getERC20Balance(
          user.address,
          SETTINGS.usdtAddress,
          signer,
          6
        );
        const maticBalance = await getMaticBalance(
          user.address,
          ethersProvider
        );

        const stakingContract = new Contract(
            SETTINGS.stakingContract, // Make sure to add this address to your SETTINGS
            StakingABI,
            signer
          );
    
          const userStakes = await stakingContract.getUserStakes(user.address);
          
        

        usersData.push({
          address: user.address,
          qvrsBalance,
          usdcBalance,
          maticBalance,
          note: user.note,
          stakes: userStakes.length,
        });
      }
      setUsers(usersData);
      saveUserData(usersData);
      alert("Balances loaded and saved successfully!");
    } catch (error) {
      console.error("Error fetching balances:", error);
    } finally {
      setLoading(false);
    }
  };

  const getERC20Balance = async (
    address,
    contractAddress,
    signer,
    decimals = 18
  ) => {
    const contract = new Contract(contractAddress, ERC20_ABI, signer);
    const balance = await contract.balanceOf(address);
    return parseFloat(formatUnits(balance, decimals));
  };

  const getMaticBalance = async (address, provider) => {
    const balance = await provider.getBalance(address);
    return parseFloat(formatUnits(balance, 18));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleNoteChange = (user, newNote) => {
    const updatedUsers = users.map((u) => 
      u.address === user.address ? { ...u, note: newNote } : u
    );
    setUsers(updatedUsers);
    saveUserData(updatedUsers); // Save updated users with notes
  };

  const sortedUsers = useMemo(() => {
    const sorted = [...users].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [users, sortConfig]);

  const filteredUsers = useMemo(() => {
    return sortedUsers.filter((user) =>
      user.address.toLowerCase().includes(searchTerm)
    );
  }, [sortedUsers, searchTerm]);

  const currentUsers = useMemo(() => {
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = Math.min(startIndex + usersPerPage, filteredUsers.length);
    return filteredUsers.slice(startIndex, endIndex);
  }, [filteredUsers, currentPage, usersPerPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const openPolyscan = (address) => {
    const polyscanUrl = `https://optimistic.etherscan.io/address/${address}`;
    window.open(polyscanUrl, "_blank");
  };

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <br />
        <br />
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <br />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col className="admin">
          <h2>User Balances</h2>
         
          <div className="mb-3">Total Users: {filteredUsers.length}</div>
          <div className="d-flex justify-content-between mb-3">
            <InputGroup>
              <FormControl
              style={{maxWidth:"300px", height:"40px", padding:"0px"}}
                type="text"
                placeholder="Search by Address"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Button variant="secondary" onClick={clearSearch} 
              className="clear"
              style={{marginRight:"10px"}}
              >
                Clear
              </Button>
            
            <FormControl
              as="select"
              className="selectSmall"
              value={usersPerPage}
              onChange={(e) => setUsersPerPage(Number(e.target.value))}
              
            >
              {[25, 50, 100, 200].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </FormControl>
            </InputGroup>
            <div>
            <Button variant="primary" onClick={fetchHolders} className="buton buton-secondary" style={{width:"150px", marginRight:"10px"}}>
              <BiRefresh className="me-1"  /> Holders
            </Button>
            </div>
            <div>
            <Button variant="primary" onClick={fetchBalances} className="buton buton-secondary" style={{width:"150px"}}>
            <BiRefresh className="me-1"  /> Balances
            </Button>
          </div>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => handleSort("address")}>Address</th>
                <th onClick={() => handleSort("qvrsBalance")}>QVRS Balance</th>
                <th onClick={() => handleSort("usdcBalance")}>USDC Balance</th>
                <th onClick={() => handleSort("maticBalance")}>MATIC Balance</th>
                <th onClick={() => handleSort("stakes")}>Stakes</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => openPolyscan(user.address)}
                      >
                        {truncateAddress(user.address)}
                      </span>
                      <Button
                        variant="link"
                        onClick={() => copyToClipboard(user.address)}
                        style={{ marginLeft: "10px" }}
                      >
                        <BiClipboard className="me-1" />
                      </Button>
                      <Button
  variant="link"
  onClick={() => navigate(`/user/${user.address}`)} // Navigate to AdminUser page
  style={{ marginLeft: "10px" }}
>
  <BiDetail className="me-1" />
</Button>
                    </div>
                  </td>
                  <td>{user.qvrsBalance?.toFixed(2) ?? "0.00"}</td>
<td>{user.usdcBalance?.toFixed(2) ?? "0.00"}</td>
<td>{user.maticBalance?.toFixed(2) ?? "0.00"}</td>
<td>{user.stakes?.toFixed(0) ?? "0"}</td>
                  <td>
                    <FormControl
                      type="text"
                      value={user.note}
                      onChange={(e) => handleNoteChange(user, e.target.value)}
                      placeholder="Add a note"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(Math.ceil(filteredUsers.length / usersPerPage)).keys()].map(
              (number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === currentPage}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default AdminUsers;
