import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const fetch = require('node-fetch');
let url = "https://fingermonkeys.com/news/getnews.php";
let settings = { method: "Get" };
let News = await fetch(url, settings).then(res => res.json()).then((json) => {return json;});

const QvrseLore = () => {
  return (
    <Container className="px-0 mt-0">
      <Row className="mb-4">
        <Col xs={12} className='mb-md-3 mb-2 mt-md-4 mt-0'>
          <h2 className='text-start'>Fmverse: The Genesis</h2>
        </Col>
        {News.map((token, index) => (
          <Col md={6} key={index}>
            <Card className='mb-3' style={{backgroundColor: "rgba(0, 0, 0, 1)",border: "1px solid rgba(69, 58, 55, 1)"}}>
              <Card.Img variant="top" src={"https://www.fingermonkeys.com/news/"+token.filename+".jpg"} alt={token.title} />
              <Card.Body>
                <Card.Title>{token.title}</Card.Title>
                <Card.Text>
                  {token.news}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default QvrseLore;