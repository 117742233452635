import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spinner, Button, ProgressBar, Alert } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import TokenSaleAndStakingABI from '../abis/TokenSaleAndStakingABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.png';
import { BiQuestionMark, BiCustomize } from 'react-icons/bi';

import { useNavigate } from 'react-router-dom';

const BuyToken = ({ provider, account, isConnected }) => {
    const [ethBalance, setEthBalance] = useState('0');
    const [usdtBalance, setUsdtBalance] = useState('0');
    const [tokenBalance, setTokenBalance] = useState('0');
    const [presales, setPresales] = useState([]);
    const [selectedPresaleIndex, setSelectedPresaleIndex] = useState(0);
    const [amountToBuy, setAmountToBuy] = useState('');
    const [amountToRecive, setAmountToRecive] = useState('');
    const [activePresale, setActivePresale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [stakes, setStakes] = useState([]);
    const [userLevel, setUserLevel] = useState(1);
    const navigate = useNavigate();
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);

        if (parseInt(level) > 1) {
            setUserLevel(parseInt(level));

        }
    };

    const openFaqs = () => {
        navigate('/FAQs');
    }

    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
        const USDTBalance = await USDTContract.balanceOf(account);
        const USDTDecimals = await USDTContract.decimals();
        setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));
        const balance = await ethersProvider.getBalance(account);
        setEthBalance(formatUnits(balance, 18));
        await getUserLevel();
    }
    // Fetch presale details from the contract
    useEffect(() => {
        const fetchPresales = async () => {
            if (!provider || !account) return;
            const ethersProvider = new BrowserProvider(provider);
            const contract = new Contract(SETTINGS.mainContract, TokenSaleAndStakingABI, ethersProvider);
            const pArr = await contract.getAllPresales();
            let data = [];
            for (let i = 0; i < pArr.length; i++) {
                const leftAmount = parseFloat(formatUnits(pArr[i].tokenAmount.toString(), 18).toString()) - parseFloat(formatUnits(pArr[i].soldAmount.toString(), 18).toString());
                data.push({
                    tokenAmount: pArr[i].tokenAmount.toString(),
                    tokenAmountDec: formatUnits(pArr[i].tokenAmount.toString(), 18).toString(),
                    price: pArr[i].price.toString(),
                    priceDec: formatUnits(pArr[i].price.toString(), 18).toString(),
                    soldAmount: pArr[i].soldAmount.toString(),
                    leftAmount: leftAmount.toFixed(0),
                    isActive: pArr[i].isActive,
                    startTime: new Date(parseInt(pArr[i].startTime.toString()) * 1000).toLocaleString()
                });
            }
            setPresales(data);
            const active = data.find((presale) => presale.isActive);
            setActivePresale(active);
            setSelectedPresaleIndex(data.indexOf(active));
        };
        if (isConnected) {
            fetchPresales();
            getBalance();
            fetchStakes();
        }
    }, [provider, account, isConnected]);
    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.mainContract, TokenSaleAndStakingABI, signer);
            const stakesDataUser = await contract.getUserStakes(account);
            const serialized = JSON.stringify(stakesDataUser, (key, value) =>
                typeof value === 'bigint' ? value.toString() : value
            );
            const stakesData = JSON.parse(serialized);
            let stakesArr = []
            for (let i = 0; i < stakesData.length; i++) {
                const stake = stakesData[i];
                stakesArr.push(
                    {
                        amount: formatUnits(stake["0"].toString(), 18),
                        timestamp: stake["1"],
                        claimed: stake["2"].claimed,
                        i
                    }
                );
            }
            setStakes(stakesArr);
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };
    const _setAmountToBuy = async (amount) => {
        setAmountToBuy(amount);
        const price = parseFloat(activePresale.priceDec);
        const tokensToRecive = parseFloat(amount) / price;
        setAmountToRecive(tokensToRecive.toFixed(4));
    };
    const handleBuyToken = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            setTxMessage("Approving USDC transfer...");
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                signer
            );
            // Convert the amount to buy to wei (USDT has 6 decimals)
            const amountToBuyWei = parseUnits(amountToBuy.toString(), 6);
            // Approve the token sale contract to spend USDT
            const approveTx = await usdtContract.approve(
                SETTINGS.mainContract,
                amountToBuyWei
            );
            await approveTx.wait();
            setTxMessage("Purchasing tokens...");
            // Now call the buyToken function
            const tokenSaleContract = new Contract(
                SETTINGS.mainContract,
                TokenSaleAndStakingABI,
                signer
            );
            const buyTx = await tokenSaleContract.buyToken(
                selectedPresaleIndex,
                amountToBuyWei
            );
            await buyTx.wait();
            setTxMessage("Tokens purchased successfully!");
            console.log("Tokens purchased with USDC!");
            // Update UI after purchase
            // Fetch updated presales information, etc.
            getBalance();
            navigate('/');
        } catch (error) {
            console.error("Transaction failed!", error);
            setTxMessage("Transaction failed. Please try again.");
        } finally {
            setLoading(false);
            // Reset or update the message after a delay
            setTimeout(() => setTxMessage(""), 5000);
        }
    };
    if (loading) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div>
            <Row>

                <Col sm={12} md={12} lg={12}>
                    {activePresale ? (
                        <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                            <div>
                                <div className="buy-token-header text-center">
                                    <h3 className='mb-md-0 mb-2' style={{ textAlign: "center" }}>BUY ${SETTINGS.tokenSymbol}</h3>
                                </div>
                                {/* Token exchange info */}
                                <div className="token-exchange-info ">
                                    <div>
                                        <img src={logoToken} style={{ width: "30px", marginRight: "6px", marginLeft: "5px", marginTop: "-5px" }} alt={SETTINGS.tokenSymbol} />
                                        1 {SETTINGS.tokenSymbol}&nbsp;&nbsp;&nbsp;=
                                        <img src={SETTINGS.usdtIcon} style={{ width: "30px", marginRight: "8px", marginLeft: "10px", marginTop: "-5px" }} alt={"USDC"} />
                                        {activePresale.priceDec} USDC
                                    </div>
                                </div>
                                {/* Swap form */}
                                <div className="swap-form ">
                                    <div className="input-group">
                                        <label className='mb-1'>Amount in USDC</label>
                                        <input
                                            type="text"
                                            value={amountToBuy}
                                            onChange={(e) => _setAmountToBuy(e.target.value)}
                                            placeholder="Amount in USDC"
                                            className='custom-select'
                                        />
                                    </div>
                                    <div className="input-group mb-2">
                                        <small style={{ marginTop: "1px" }} >You get:</small>
                                        <img src={logoToken} style={{ width: "18px", marginRight: "8px", marginLeft: "12px", marginBottom: "4px" }} alt={"Logo"} />
                                        <p className="mb-1 fw-bold">{!amountToRecive || isNaN(amountToRecive) ? '0.0000' : amountToRecive}</p>
                                    </div>
                                </div>
                                <div className="gas-info mt-3">
                                    *After purchase your {SETTINGS.tokenSymbol} tokens will be available in your wallet.
                                </div>
                            </div>
                            <div>
                                <Button className="buton" onClick={() => handleBuyToken()}>
                                    BUY TOKEN
                                </Button>
                                <center>
                                    <span style={{ marginTop: "16px", display: "block", fontWeight: "500" }}>
                                        <small>Your balance: </small>
                                        <img src={SETTINGS.usdtIcon} style={{ width: "18px", marginRight: "10px", marginLeft: "10px", marginTop: "-2px" }} alt={"USDC"} />
                                        <small className='fw-bold'>{usdtBalance} USDC</small>
                                    </span>
                                </center>
                            </div>
                        </div>
                    ) : (
                        <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                            <div className="buy-token-header text-center">
                                <h2 className='mb-md-0 mb-2' style={{ textAlign: "center" }}>BUY ${SETTINGS.tokenSymbol}</h2>
                            </div>
                            <Alert variant="secondary" style= {{background: 'rgba(25, 25, 21, 1)'}}>
                                <div className='py-md-3 py-2 px-md-2 px-1 text-center' style={{ margin: "20px 0px",color: "rgba(165, 146, 142, 1)" }}>
                                    <h5>Reach Level 2</h5>
<br />
                                    To unlock the ability to buy ${SETTINGS.tokenSymbol} tokens, you need to reach Level 2. 
                                    <br />
                                    <br />
                                    You can do this by crafting two LAND #1 NFTs together.
                                    <br />
                                    <br />
                                    <Button variant="primary mt-md-2 mt-0" onClick={openFaqs} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                        Read FAQs
                    </Button>
                                </div>
                            </Alert>
                        </div>
                    )}
                </Col>

                <Col sm={12} md={8} lg={6} xl={6} style={{ display: "none" }} >
                    <div className="staking-container">
                        <div style={{ padding: '0 10px' }}>
                            {stakes.length > 0 ? (<h4>Your vestings</h4>) : (<></>)}
                            {stakes.map((stake, index) => (
                                <div key={index} className="mb-3 pokemon-card" >
                                    <Card.Body>
                                        <Row>
                                            <Col xs={8} md={8}>
                                                <h6 style={{ marginTop: "15px" }}>
                                                    <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "10px", marginLeft: "10px", marginTop: "-5px" }} alt={SETTINGS.tokenSymbol} />
                                                    Vested: {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)}
                                                    <small> {SETTINGS.tokenSymbol}</small>
                                                </h6>
                                            </Col>
                                            <Col sm={4} md={4}>
                                                <ProgressBar
                                                    variant='warning'
                                                    striped
                                                    animated
                                                    now="100"
                                                    style={{ height: '10px', fontSize: '1rem', marginTop: "18px", marginRight: "20px" }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </div>
                            ))}

                            {/* Presales info */}
                            <h4 className='pt-lg-0 pt-2 mb-3'>Presales</h4>
                            {presales.map((presale, index) => (
                                <div key={index} className="mb-3 pokemon-card">
                                    <div >
                                        <h5 className='mt-0 mb-2 pb-2'>Presale {index + 1}</h5>
                                        <p className="mb-2 small">
                                            Price:
                                            <img src={SETTINGS.usdtIcon} style={{ width: "18px", marginRight: "6px", marginLeft: "10px", marginTop: "-2px" }} alt={"USDC"} />
                                            <b>{formatUnits(presale.price.toString(), 'ether')} USDC</b>
                                        </p>
                                        <p className="mb-3 pb-1 small">
                                            Available:
                                            <img src={logoToken} style={{ width: "18px", marginRight: "6px", marginLeft: "10px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
                                            <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(presale.leftAmount)} {SETTINGS.tokenSymbol}</b>
                                        </p>
                                        <ProgressBar
                                            striped
                                            animated={presale.isActive}
                                            now={presale.isActive ? ((presale.leftAmount / presale.tokenAmount) * 100) + 5 : "100"}
                                            variant={presale.isActive ? "success" : "danger"}
                                            style={{ height: '10px', fontSize: '1rem' }}
                                        />
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default BuyToken;
